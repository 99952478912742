@use '@cigna/cigna_csg_gsg_cipublic-shared/styles/cipublic' with ($font-path: '~@cigna/cigna_csg_gsg_cipublic-shared/fonts/'
);
@use '@cigna/cigna_csg_gsg_cipublic-shared/styles/grid';
@use '@cigna/cigna_csg_gsg_cipublic-bundle/styles/not-defined';
@use '@cigna/cigna_csg_gsg_cipublic-shared/styles/mixins/breakpoints' as *;

#cipublic-header {
  &.sites-header-with-tfn {
    background-color: var(--cipublic-color-primary-100);

    .cipublic-container {
      padding-bottom: var(--cipublic-size-s);
      padding-top: var(--cipublic-size-s);
    }

    .header-logo {
      max-height: 66px;
      max-width: 192px;
    }

    .right-header-link {
      margin-top: var(--cipublic-size-m);

      @include media-breakpoint-up(xl) {
        margin-top: 0;
        text-align: right;
      }
    }

    nav {
      @include media-breakpoint-up(xl) {
        align-items: center;
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}